.product-page-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  
  .product-page-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 3px 7px rgba(0,0,0,0.3);
    max-width: 500px;
    width: 90%; /* use percentage instead of fixed width */
    position: relative;
    margin: auto; /* this will help in centering the modal */
  }
  
  .product-page-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 1rem;
  }
  
  .product-page-close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
  
  /* Media Queries for smaller screens */
  @media (max-width: 600px) {
    .product-page-content {
      padding: 1rem;
      width: 95%; /* take up slightly more width on very small screens */
    }
    
    .product-page-close {
      top: 0.2rem;
      right: 0.2rem;
    }
  }
  
  .section-title-style {
    font-size: 48px;        /* Large text size */
    font-weight: bold;      /* Make it bold */
    text-transform: uppercase; /* UPPERCASE LETTERS */
    color: #2a2a2a;        /* Dark gray color, modern look */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
    letter-spacing: 1.5px; /* Spacing out letters for a more elegant look */
    margin-bottom: 20px;   /* Space below the title */
    padding: 10px 0;       /* Padding above and below text */
    padding-left: 60px;
    display: inline-block; /* Allows the border to only take up the width of the text */
  
    
    
    transition: all 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .section-title-style:hover {
   
  /*nothong here*/
  
  }
  
  /* Media query for tablets */
@media (max-width: 768px) {
  .section-title-style {
    font-size: 36px; /* Slightly smaller text size for tablet devices */
    padding: 8px 0; /* Adjust padding */
    margin-bottom: 15px; /* Adjust space below the title */
    letter-spacing: 1px; /* Adjust letter spacing */
    /* Adjust other properties as needed */
  }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .section-title-style {
    font-size: 24px; /* Smaller text size for mobile devices */
    padding: 5px 0; /* Smaller padding */
    margin-bottom: 10px; /* Less space below the title */
    letter-spacing: 0.5px; /* Less letter spacing */
    border-bottom: 1px solid #b8b75c; /* Thinner underline */
    /* Adjust other properties as needed */
  }
}