/* app.css */

body {
  /* This should be set in the useEffect hook in App.js */
}

/* Header styles */
.header-section {
  background: url('./BGImage.jpg') center/cover no-repeat;
  min-height: 100vh;
  cursor: none;
  /* This hides the cursor */

}

.revealing-overlay {
  content: '';
  position: absolute;
  background: url('./BGimage2.jpg') center/cover no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background 0.5s;
  mask-image: radial-gradient(circle, black 100px, transparent 100px);
  mask-repeat: no-repeat;
  mask-size: 200px 200px;
  pointer-events: none;
}

.header-title {
  font-family: 'Oswald', sans-serif;
  font-size: 5vw;
  /* Adjusted for responsiveness */
  color: white;
  margin: 0;
  /* Remove default margin */
  text-align: center;
  z-index: 10000;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.header-subtitle {
  font-size: 3vh;
  /* Adjusted for responsiveness */
  padding-bottom: 20px;
  padding-top: 250px;
  color: white;
  text-align: center;
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .header-title {
    font-size: 8vw;
    /* Larger size for better readability on small screens */
  }

  .header-subtitle {
    font-size: 5vh;
    /* Larger size for better readability on small screens */
  }

  .header-section {
    padding: 1rem;
    /* Reduce padding on small screens */
  }
}

/* Search Section */
.search-section {
  padding: 1rem;
}

/* Products Section */
.products-section {
  background-color: white;
  padding: 0 8%;
  margin: auto;
  min-height: 60vh;
}

.products-container {
  padding: 2rem 0;
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* This will create a flexible grid with a minimum column size of 300px */
  gap: 1rem;
  /* Adjust the space between cards */
  margin: 0 auto;
  /* Center the grid */
}


.card {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease-in-out;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  /* make card width responsive */
  max-width: 300px;
  /* set max card width */
  height: auto;
  margin: 0 auto;
}


.card .relative {
  /* This targets the div that wraps the CardMedia */
  border-radius: 20px 20px 20px 20px;
  /* Top corners rounded */
  overflow: hidden;
  /* Ensures the image respects the border-radius */
  height: 250px;
}

.card .MuiCardMedia-root {
  object-fit: cover;
  width: 100%;

  height: 250px;

  object-position: center;

  border-radius: 20px 20px 0 0;

}

.card .MuiCardContent-root {

  border-radius: 20px 20px 20px 20px;


}


.card .button-class {
  padding: 10px 24px;
  font-size: 1rem;
  margin-top: 20px;

}


@media (max-width: 600px) {
  .card .button-class {
    padding: 4px 8px;
    font-size: 0.7rem;
    margin-top: 20px;

  }
}

.section-title-style {
  font-size: 48px;
}