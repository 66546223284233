@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ensure the main page fills at least the whole height of the screen and is centered */
.designs-page {
  @apply relative w-full min-h-screen overflow-auto flex justify-center items-center ; /* mt-24 might need to be adjusted to match your navbar's height */
}

.designs-page .image-container {
  transform: rotate(0deg); /* Reset any rotation */
  padding: 0; /* Reset padding */
  /* Other styles */
}



/* Responsive grid layout and padding */
.overlay {
  @apply absolute top-0 left-0 w-full min-h-screen bg-white grid grid-cols-1 sm:grid-cols-2 grid-rows-2 gap-4 z-10 p-2 sm:p-5 md:pt-40; /* Adds top padding of 5rem (80px) on screens 768px and wider */
}


/* Center cutouts and text boxes within their grid cells, adjust sizing for smaller screens */
.cutout-container {
  @apply flex items-center justify-center relative;
}

.cutout {
  @apply w-full max-w-[550px] h-auto bg-cover bg-center;
  aspect-ratio: 1 / 1; /* This will ensure that the width and height are always the same */
  clip-path: circle(50% at center); /* This will make the cutout a perfect circle */
}

/* Center introduction text - removed absolute positioning, with responsive padding */
.introduction {
  @apply w-full p-2 sm:p-5 text-center z-20 flex justify-center items-center;
}

/* Flex container for images and text, with responsive adjustments */
.image-container {
  @apply flex items-center justify-center w-full;
}

/* Center text boxes within their grid cells - removed additional margin, with responsive font sizing */
.text-box {
  @apply p-2 sm:p-5 w-full flex flex-col justify-center items-start text-left font-sans text-sm sm:text-base text-gray-800 leading-6 uppercase font-bold;
}

/* Ensure grid items are centered within their cells */
.top-left, .bottom-right, .top-right, .bottom-left {
  @apply col-span-1 row-span-1 flex justify-center items-center;
}

/* Adjust the order on mobile screens */
@media (max-width: 640px) { /* Adjust this to the breakpoint you are targeting */
  .top-left {
    @apply order-1;
  }
  .top-right {
    @apply order-4;
  }
  .bottom-left {
    @apply order-2;
  }
  .bottom-right {
    @apply order-3;
  }
}

/* Feature sections styled for text left alignment with responsive text sizing */
.feature {
  @apply text-left;
}

.feature-icon {
  @apply w-6 h-auto mb-2.5;
}

.feature-title {
  @apply text-lg sm:text-xl mb-4 text-left;
}

.feature-description {
  @apply text-sm sm:text-base text-gray-600 text-left;
}

.feature-divider {
  @apply border-none h-0.5 bg-gray-300 my-5;
}

.icon {
  @apply w-7 h-7;
}

